import React, { useState } from 'react';
import '../App.css';
import GoBack    from './GoBack';
import SocialIcons from './SocialIcons';

import pic1 from '../images/digital/Miscellaneous/Deodar.png';
import pic4 from '../images/digital/Miscellaneous/CentralParkBird.png';
import pic7 from '../images/digital/Miscellaneous/watch.jpg';
import pic8 from '../images/digital/Miscellaneous/DnD.png';
import pic12 from '../images/digital/Miscellaneous/rain.png';
import pic13 from '../images/digital/Miscellaneous/fixItGarage3Color.png';
import pic15 from '../images/digital/Miscellaneous/BeetaRedo.png';
import pic16 from '../images/digital/Miscellaneous/bugFamily.png';
import pic17 from '../images/digital/Miscellaneous/RefractoryPanRed.png';
import pic18 from '../images/digital/Miscellaneous/duskKey.png';
import pic19 from '../images/digital/Miscellaneous/elfNoble.png';
import pic20 from '../images/digital/Miscellaneous/Rhymez.png';
import pic21 from '../images/digital/Miscellaneous/sandTemple4.png';
import pic22 from '../images/digital/Miscellaneous/Spida.png';
import pic23 from '../images/digital/Miscellaneous/AsadonProfessors.png';
import pic24 from '../images/digital/Miscellaneous/AsadonProfessors2.png';
import pic25 from '../images/digital/Miscellaneous/moi.png';
import pic26 from '../images/digital/Miscellaneous/MsAmphibia.png';

import ldpic1 from '../images/digital/LoveDianaCategory/DianaPremiumAssetAstronaut2.png';
import ldpic2 from '../images/digital/LoveDianaCategory/DianaPremiumAssetsSketch.png';
import ldpic3 from '../images/digital/LoveDianaCategory/DianaPremiumAssetScientist.png';
import ldpic4 from '../images/digital/LoveDianaCategory/DianaPremiumAssetPrincess2.png';
import ldpic5 from '../images/digital/LoveDianaCategory/DianaPremiumAssetWonder.png';
import ldpic6 from '../images/digital/LoveDianaCategory/DianaRomaTeamColor.png';
import ldpic7 from '../images/digital/LoveDianaCategory/DianaBows2.png';
import ldpic8 from '../images/digital/LoveDianaCategory/wand3.png';
import ldpic9 from '../images/digital/LoveDianaCategory/BadGuysLineup2.png';
import ldpic10 from '../images/digital/LoveDianaCategory/BadGuysLineup.png';
import ldpic11 from '../images/digital/LoveDianaCategory/BadGuysLineup3.png';
import ldpic13 from '../images/digital/LoveDianaCategory/SlogPitchImageGray.png';
import ldpic14 from '../images/digital/LoveDianaCategory/HattiePitchImageColor.png';
import ldpic15 from '../images/digital/LoveDianaCategory/BorisPerspectives.png';
import ldpic16 from '../images/digital/LoveDianaCategory/RomaTurnAround3.png';
import ldpic17 from '../images/digital/LoveDianaCategory/DianaExpressions1.png';
import ldpic18 from '../images/digital/LoveDianaCategory/RomaExpressions2.png';
import ldpic19 from '../images/digital/LoveDianaCategory/MimiLoveDiana.png';
import ldpic20 from '../images/digital/LoveDianaCategory/CrystalCove.png';

import wfpic1 from '../images/digital/WeedFriends/Bindi.png';
import wfpic2 from '../images/digital/WeedFriends/Dani.png';
import wfpic3 from '../images/digital/WeedFriends/Jasper.png';

import ehbeepic1 from '../images/digital/EhbeeCategory/EhbeeFamilyLineUpAlphaSpace.png';
import ehbeepic2 from '../images/digital/EhbeeCategory/EhbeeFamilyLineUpHat.png';
import ehbeepic3 from '../images/digital/EhbeeCategory/The Miltons1.png';
import ehbeepic4 from '../images/digital/EhbeeCategory/EhbeeFamilyZombies.png';
import ehbeepic5 from '../images/digital/EhbeeCategory/EhbeeFamilyZombieLineUpRegular.png';
import ehbeepic6 from '../images/digital/EhbeeCategory/Empa-nada.png';
import ehbeepic7 from '../images/digital/EhbeeCategory/LoglinesRoarTrip1.png';
import ehbeepic8 from '../images/digital/EhbeeCategory/PullMyFinger.png';

import modeling1 from '../images/digital/Modeling3D/donut.jpeg';
import modeling2 from '../images/digital/Modeling3D/gears.jpeg';
import modeling3 from '../images/digital/Modeling3D/livingroom.jpeg';

import onyx1 from '../images/digital/OnyxFamily/earphoneproblems.png';
import onyx2 from '../images/digital/OnyxFamily/sodachallenge.png';
import onyx3 from '../images/digital/OnyxFamily/wecantcarvepumpkins.png';

// import pic9 from '../images/digital/CrystalCove.png';
// import pic10 from '../images/digital/DreamCatcherRoom.png';
// import pic2 from '../images/digital/ArielRiley12A.png';
// import pic3 from '../images/digital/BeetaRedo.png';
// import pic6 from '../images/digital/Raven.png';

function App() {

    const lovedianas = [
      { name: "Diana Astronaut", src: ldpic1},
      { name: "Astronaut Sketch", src: ldpic2},
      { name: "Diana Scientist", src: ldpic3},
      { name: "Diana", src: ldpic4},
      { name: "Wonder", src: ldpic5},
      { name: "Diana and Roma", src: ldpic6},
      { name: "Bows", src: ldpic7},
      { name: "Wands", src: ldpic8},
      { name: "Bad Guys", src: ldpic9},
      { name: "Bad Guys in Color", src: ldpic10},
      { name: "Bad Guys again", src: ldpic11},
      { name: "Pidgeon & Slog in Gray", src: ldpic13},
      { name: "Boris Perspectives", src: ldpic15},
      { name: "Roma Turning Around", src: ldpic16},
      { name: "Diana Expressions", src: ldpic17},
      { name: "Roma Expressions", src: ldpic18},
      { name: "Bad Guys again", src: ldpic19},
      { name: "Crystal Cove", src: ldpic20},
      { name: "Hattie in Color", src: ldpic14}
    ]

    const weedfriends = [
      { name: "Bindi", src: wfpic1},
      { name: "Dani", src: wfpic2},
      { name: "Jasper", src: wfpic3}
    ]

    const ehbees = [
      { name: "Ehbee in Space", src: ehbeepic1},
      { name: "Ehbee Lineup", src: ehbeepic2},
      { name: "The Miltons", src: ehbeepic3},
      { name: "Ehbee as Zombies", src: ehbeepic4},
      { name: "Ehbee Zombie lineup", src: ehbeepic5},
      { name: "Ehbee Bakesale", src: ehbeepic6},
      { name: "Ehbee Roadtrip", src: ehbeepic7},
      { name: "Lost Finger", src: ehbeepic8}
    ]

    const modelings = [
      { name: "donut", src: modeling1},
      { name: "gears", src: modeling2},
      { name: "livingroom", src: modeling3}
    ]

    const onyxs = [
      { name: "Earphone Problems", src: onyx1},
      { name: "Soda Challenge", src: onyx2},
      { name: "We Can't Carve Pumpkins", src: onyx3}
    ]

    const digitals = [
        // { name: "ArielRiley", src: pic2},
        // { name: "Beeta", src: pic3},
        // { name: "Raven", src: pic6},
        // { name: "CrystalCove", src: pic9},
        // { name: "DreamCatcherRoom", src: pic10},
        { name: "Spida", src: pic22},
        { name: "asadon", src: pic23},
        { name: "asadon1", src: pic24},
        { name: "moi", src: pic25},
        { name: "msamphibia", src: pic26},
        { name: "Moira Expression", src: pic15},
        { name: "Naptime", src: pic16},
        { name: "Dusk Key", src: pic17},
        { name: "rain", src: pic12},
        { name: "Garage", src: pic13},
        { name: "Deodar", src: pic1},
        { name: "DnD", src: pic8},
        { name: "Watch", src: pic7},
        { name: "CentralParkBird", src: pic4},
        { name: "Sand Temple", src: pic21},
        { name: "Elf Noble", src: pic19},
        { name: "Rhymez", src: pic20},
        { name: "Dusk Key", src: pic18}
      ]

      //Img Modal
      let modal;
      let original;
      let bodyModal;

            
            const [imgOpen, setImgOpen] = useState(false);
            
          
 
             const imageHandler = (e) => {
                modal = document.querySelector('.modal');
                original = document.querySelector('.full-img');
                bodyModal = document.querySelector('body');

                 setImgOpen(!imgOpen);
                //  console.log('clicked', imgOpen);
                //  console.log(e.target.getAttribute('src'));
                 bodyModal.classList.add('open');
                 modal.classList.add('open');
                 original.classList.add('open');
                 original.src = `${e.target.getAttribute('src')}`;
                 bodyModal.setAttribute('style', 'overflow-y: hidden;');
             };

      const modalClickHandler = (e) => {
        modal = document.querySelector('.modal');
        original = document.querySelector('.full-img');
        bodyModal = document.querySelector('body');
    
        if (e.target.classList.contains('open')) {
          modal.classList.remove('open');
          original.classList.remove('open');
          bodyModal.classList.remove('open');
          bodyModal.setAttribute('style', 'overflow-y: auto;');
        }
      };

 


    return ( 
        <div className = "sub-page digital">

            <div className='modal' onClick={modalClickHandler}>
                 <img src='' alt='' className='full-img' onClick={modalClickHandler} />
            </div>

            <div className = "page-title">
            <h1>Digital</h1>
            <GoBack />
            </div>
            <SocialIcons />
            <div className = "page-content">

        
            <h2>3D Modeling</h2>
            {modelings.map((modeling, index) => (
                <img
                key={index}
                height="250"
                src= {modeling.src}
                alt = {modeling.name}
                style={{ maxWidth: 400 }}
                onClick={imageHandler}
                />
          ))}


            <h2>Personal art work</h2>
            {digitals.map((digital, index) => (
                <img
                key={index}
                height="250"
                src= {digital.src}
                alt = {digital.name}
                style={{ maxWidth: 400 }}
                onClick={imageHandler}
                />
          ))}

<h2>Onyx Family</h2>
            {onyxs.map((onxy, index) => (
                <img
                key={index}
                height="250"
                src= {onxy.src}
                alt = {onxy.name}
                style={{ maxWidth: 400 }}
                onClick={imageHandler}
                />
          ))}


<h2>Ehbee Family Show Concept Art</h2>

{ehbees.map((ehbee, index) => (
    <img
    key={index}
    height="250"
    src= {ehbee.src}
    alt = {ehbee.name}
    style={{ maxWidth: 400 }}
    onClick={imageHandler}
    />
))}

<h2>The Weeds Concept Art</h2>

{weedfriends.map((weedfriend, index) => (
    <img
    key={index}
    height="250"
    src= {weedfriend.src}
    alt = {weedfriend.name}
    style={{ maxWidth: 400 }}
    onClick={imageHandler}
    />
))}


<h2>Love Diana Concept and Pitch Art</h2>

{lovedianas.map((lovediana, index) => (
    <img
    key={index}
    height="250"
    src= {lovediana.src}
    alt = {lovediana.name}
    style={{ maxWidth: 400 }}
    onClick={imageHandler}
    />
))}
            </div>

      
        </div>
    );
}

export default App;