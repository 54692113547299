
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <p>
//           My apologies, we are down for maintance.
//         </p>
 
//       </header>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import './App.css';
import Nav from './components/Nav';
import Animation from './components/Animation';
import Digital from './components/Digital';
// import Sketches from './components/Sketches';
import About    from './components/About';

import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { CSSTransition } from "react-transition-group";

function App() {
    // const routes = [
    //     { path: '/animation', name: 'Animation', Component: Animation },
    //     { path: '/digital', name: 'digital', Component: Digital },
    //     { path: '/sketches', name: 'sketches', Component: Sketches },
    //     { path: '/about', name: 'about', Component: About}
    //   ]

    return ( 
  

      <BrowserRouter>

      <Routes>

      <Route index element={<Nav  />} />
      <Route path='/animation' element={<Animation/>} />
      <Route path='/digital' element={<Digital />} />
      <Route path='/about' element={<About />} />

      </Routes>
      
      </BrowserRouter>


    );
}

export default App;