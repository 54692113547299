import React from 'react';
import '../App.css';
import { SocialIcon } from 'react-social-icons';

const SocialIcons = (props) => {
  return (
    <div className='social-icons'>
      <ul>
        <li>
          <SocialIcon
            className = "my-social-icon"
            url='https://www.linkedin.com/in/whitsaboutcha/'
            style={{ height: 40, width: 40 }}
            target='_blank'
            rel='noopener noreferrer'
            bgColor="#31185E"
          />
        </li>
        <li>
          <SocialIcon
            className = "my-social-icon"
            url='https://instagram.com/WhitsAboutCha'
            style={{ height: 40, width: 40 }}
            target='_blank'
            rel='noopener noreferrer'
            bgColor="#31185E"
          />
        </li>
        <li>
        <SocialIcon
            className = "my-social-icon"
            url='https://twitter.com/WhitsAboutCha'
            style={{ height: 40, width: 40 }}
            target='_blank'
            rel='noopener noreferrer'
            bgColor="#31185E"
          />
        </li>
        <li>
          <SocialIcon
            className = "my-social-icon"
            url='mailto:hello@whitsaboutcha.com'
            style={{ height: 40, width: 40 }}
            target='_blank'
            rel='noopener noreferrer'
            bgColor="#31185E"
          />
        </li>
      </ul>
    </div>
  );
};

export default SocialIcons;