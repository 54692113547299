import React from 'react';
import '../App.css';
import GoBack    from './GoBack';
import SocialIcons from './SocialIcons';

import pic1 from '../images/aboutme/pic3.png'
// import pic2 from '../images/aboutme/whitney.jpeg';

function About() {
    return ( 
        <div className = "sub-page about">
            <div className = "page-title">
            <h1>About</h1>
            <GoBack />
            </div>
            <SocialIcons />
           
            <div className = "page-content">
                <div><img src= {pic1} alt="carton of Whitney"/></div>
                <div className = "about-description">
                My name is Whitney Hudson and I've been working in animation since 2018. For as long as I can remember drawing has been my hobby but I’ve always loved animation. The excitement of seeing work that starts out as an idea or sketch, come to life is what continues to drive me to create. I enjoy the process and it's been very fulfilling feeling my growth as an artist, and watching this hobby become a career.
                </div>

                {/* <div id="cf">
                    <img class="bottom" src= {pic2}  />
                    <img class="top" src= {pic1} />
                </div> */}
                {/* <div className = "about-description">
                My name is Whitney Hudson and I've been working in animation since 2018. For as long as I can remember drawing has been my hobby but I’ve always loved animation. The excitement of seeing work that starts out as an idea or sketch, come to life is what continues to drive me to create. I enjoy the process and it's been very fulfilling feeling my growth as an artist, and watching this hobby become a career.
                </div> */}
            </div>

        </div>
    );
}

export default About;