import React from 'react';
import '../App.css';
import GoBack    from './GoBack';
import SocialIcons from './SocialIcons';

function Animation() {
    const videos = [
        { name: "Whitney Hudson's 2021REEL", src: 'https://www.youtube.com/embed/gk9zaMHyYr0'},
        { name: "Jason Learns Colors...", src: 'https://www.youtube.com/embed/dQaHnIxWn5I'},
        // { name: "Virtually Free Trailer", src: 'https://www.youtube.com/embed/5GvGvu8fd6Q'},
        { name: "Diana and Roma Magic Super Hero Doll", src: 'https://www.youtube.com/embed/e4EhPnvBil4'},
        { name: "Static Shock", src: 'https://www.youtube.com/embed/7VD_7Js6Nts'},
        { name: "Room 13", src: 'https://www.youtube.com/embed/UFiwwl3Oedk'},
        // { name: "Lost in the Forest", src: 'https://www.youtube.com/embed/nvhYiNZerO8'}
        // { name: "Super Mario Trump", src: 'https://player.vimeo.com/video/209288306'}
      ]


    return ( 
        <div className = "sub-page animation">
            <div className = "page-title">
            <h1>Animation</h1>
            <GoBack />
            </div>
            <SocialIcons />
            <div className = "page-content">
            {videos.map((video, index) => (
                <iframe 
                className= "embeded-video"
                key={index}
                width="560" 
                height="315" 
                src= {video.src}
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                title="video"
                >
                </iframe>  
          ))}
            </div>

            
        </div>
    );
}

export default Animation;