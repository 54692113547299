import React from 'react';
// import '../App.css';
// import {useHistory} from 'react-router-dom';
import { Link } from 'react-router-dom'

function GoBack() {
    // let history = useHistory();

    return ( 
        <div>
            {/* <h2 className = "back-button" onClick={() => history.push('/')}>Back</h2> */}
            <Link to='/' className = "back-button">
                    <h2>back</h2>
                </Link>
        </div>
    );
}

export default GoBack;