import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom'
import animatedImage from '../images/1.png'
import digitalImage from '../images/2.png'
// import sketchImage from '../images/newSketch.png'
import aboutImage from '../images/aboutme/pic3.png'

function Nav() {
    return ( 
        <div>
<div class="firefly-container">
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
  <div class="firefly"></div>
</div>

        <nav className = "nav-images">
             <h1 className="page-name">Whitney Hudson</h1>
             <div className = "reel">
                <iframe 
                className= "embeded-video"
                width="100%" 
                height="100%" 
                src= "https://www.youtube.com/embed/gk9zaMHyYr0"
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" 
                // allowFullScreen
                title="video"
                >
                </iframe>  
             </div>
                <Link to='/animation' className = "img-link animation">
                    <img src={animatedImage} alt="animated"/>
                    <h2>Animation</h2>
                </Link>
                <Link to='/digital' className = "img-link digital">
                <img src={digitalImage} alt="animated"/>
                    <h2>Digital</h2>
                </Link>
                {/* <Link  to='/sketches' className = "img-link sketches">
                <img src={sketchImage} alt="animated"/>
                    <h2>Sketches</h2>
                </Link> */}
                <Link  to='/about' className = "img-link about">
                <img src={aboutImage} alt="animated"/>
                    <h2>About</h2>
                </Link>
        </nav>
        </div>
    );
}

export default Nav;